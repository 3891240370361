<template>
  <section class="user-store-selection" :class="{
    'user-store-selection--single': is_new_store
  }">
    <admin_corner_icon />

    <div v-if="is_new_store">
      <h1 class="user-store-selection__store-single-title" v-html="$translate('user_store_selection.first_store_title')"/>
      <div class="user-store-selection__store user-store-selection__store-single">
        <div class="user-store-selection__message">
          <a :href="get_correct_domain(projects[0].project_id, projects[0].project_name_id, projects[0].domain)" target="_blank">
            <basic_button
              :text="$translate('user_store_selection.visit_store')"
              :bordered="true"
            />
          </a>
          <triple_arrow_button
            :text="$translate('user_store_selection.visit_admin')"
            :small="true"
            @click.native="select_store(projects[0].project_id)"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p class="user-store-selection__title md-display-1">{{ $translate("user_store_selection.title") }}</p>

      <div v-if="projects.length" class="user-store-selection__stores">
        <div
          v-for="{ project_id, project_name, icon, domain, project_name_id } in projects"
          :key="project_id"
          class="user-store-selection__store md-elevation-2"
        >
          <div class="md-title">
            <md-avatar v-if="get_store_icon(icon)">
              <img :src="get_store_icon(icon)" alt="store icon">
            </md-avatar>

            <span v-if="project_name"><b>{{ (project_name).toUpperCase() }}</b></span>
            <span v-else>{{ $translate("user_store_selection.project_name_undefined") }}</span>
          </div>
          <div class="user-store-selection__message">
            <a :href="get_correct_domain(project_id, project_name_id, domain)" target="_blank">
              <basic_button
                :text="$translate('user_store_selection.visit_store')"
              />
            </a>
            <highlighted_button
              :text="$translate('user_store_selection.visit_admin')"
              @click.native="select_store(project_id)"
            />
          </div>
        </div>

        <div
          class="user-store-selection__new-store-button"
          @click="store_creation_modal = true"
        >
          <md-icon>add</md-icon>
          <md-tooltip md-direction="bottom">{{ $translate("user_store_selection.create_new_store") }}</md-tooltip>
        </div>
      </div>

      <md-empty-state
        v-else
        md-icon="store"
        :md-label="$translate('user_store_selection.messages.first_store_title')"
        :md-description="$translate('user_store_selection.messages.first_store_message')"
      />

      <div class="user-store-selection__actions" v-if="!email_modal">
        <md-button class="md-accent" @click="log_out_user({ $router, route: '/' })">{{ $translate("user.log_out") }}</md-button>
        <md-button
          class="md-icon-button"
          @click="settings_modal = true"
        >
          <md-tooltip md-direction="left">{{ $translate("user.settings") }}</md-tooltip>
          <md-icon>settings</md-icon>
        </md-button>
      </div>

      <md-dialog :md-active.sync="store_creation_modal" @md-closed="clear_modal" :md-click-outside-to-close="false">
        <md-dialog-title>{{ $translate("user_store_selection.create_new_store") }}</md-dialog-title>

        <md-dialog-content class="user-store-selection__modal">
          <md-field :class="{ 'md-invalid': !project_name }">
            <label for="projectName">{{ $translate("new_user.inputs.project_name") }}</label>
            <md-input name="projectName" id="projectName" autocomplete="projectName" v-model="project_name" type="text"/>
            <span class="md-helper-text">{{ $translate("new_user.messages.required_field") }}</span>
            <span class="md-error">{{ $translate("user_store_selection.messages.project_name_required") }}</span>
          </md-field>

          <md-field :class="{ 'user-store-selection__error': show_domain_error }">
            <label for="projectDomain">{{ $translate("new_user.inputs.project_domain") }}</label>
            <md-input
              name="projectDomain"
              id="projectDomain"
              :value="project_domain"
              @change="event => update_domain(event.target.value)"
              type="text"
            />
            <span v-if="show_domain_error" class="md-helper-text">{{ $translate(`project_configuration.domain_error`) }}</span>
          </md-field>

          <md-field>
            <label for="languages">{{ $translate("user_store_selection.select_store_languages") }}</label>
            <md-select v-model="selected_languages" name="languages" id="languages" multiple>
              <md-option
                v-for="language in languages"
                :key="language"
                :value="language"
                class="user-store-selection__language"
              >
                {{ $translate(`languages.${language}`) }}
                <img
                  :data-cy="`${language}`"
                  :src="`/static/icons/countries/${language}.svg`"
                  :alt="`${language} flag`"
                />
              </md-option>
            </md-select>
            <span class="md-helper-text">{{ $translate("new_user.messages.required_field") }}</span>
          </md-field>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="store_creation_modal = false">{{ $translate("cancel") }}</md-button>
          <md-button
            class="md-primary md-raised"
            :disabled="!project_name || !selected_languages.length || !is_domain_valid"
            @click="create_store"
          >{{ $translate("create") }}</md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog
        :md-active.sync="email_modal"
        :md-close-on-esc="false"
        :md-click-outside-to-close="false"
      >
        <md-dialog-title>{{ $translate("user_store_selection.missing_email") }}</md-dialog-title>

        <md-dialog-content class="user-store-selection__modal">
          <div>{{ $translate("user_store_selection.messages.add_email_address") }}</div>

          <md-field :class="{ 'user-store-selection__error': email && !is_email_valid }">
            <label for="projectDomain">{{ $translate("new_user.inputs.email") }}</label>
            <md-input v-model="email" type="email"/>
            <span v-if="email && !is_email_valid" class="md-helper-text">
              {{ $translate("user_store_selection.messages.invalid_email") }}
            </span>
          </md-field>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button class="md-primary" :disabled="!is_email_valid" @click="update_user_email">{{ $translate("save") }}</md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="settings_modal">
        <md-dialog-title>{{ $translate("user.settings") }}</md-dialog-title>

        <md-dialog-content class="user-store-selection__modal">
          <div class="md-body-2">{{ $translate("user_store_selection.settings.delete_account") }}</div>

          <div>
            <md-checkbox v-model="account_deletion_acknowledgment" :disabled="is_any_store_admin">
              {{ $translate("user_store_selection.settings.delete_account_acknowledgment") }}
            </md-checkbox>
          </div>

          <md-button
            class="md-raised md-accent"
            :disabled="!account_deletion_acknowledgment"
            @click="delete_account"
          >{{ $translate("delete") }}</md-button>

          <div v-if="is_any_store_admin" class="md-caption user-store-selection__deletion-message">
            {{ $translate("user_store_selection.settings.remove_user_tooltip") }}
          </div>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="settings_modal = false">{{ $translate("close") }}</md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="new_store_message_modal" class="user-store-selection__modal">
        <md-dialog-title>{{ $translate("user_store_selection.new_store.title") }}</md-dialog-title>

        <md-dialog-content>
          <p v-if="!new_store_domain" class="md-subheading">
            {{ $translate("user_store_selection.new_store.new_store_domain.beginning") }}
            <a :href="correct_stores_domain" target="_blank">
              {{ `${stores_preview_domain}/${new_store_name_id || new_store_id}` }}
            </a>
            {{ $translate("user_store_selection.new_store.new_store_domain.end") }}
          </p>

          <div class="md-body-2" v-html="$translate('user_store_selection.new_store.message')" />

          <div class="user-store-selection__warning-message md-body-2 md-warning">
            {{ $translate("user_store_selection.new_store.warning") }}
          </div>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="new_store_message_modal = false">{{ $translate("close") }}</md-button>
          <md-button @click="select_store(new_store_id)" class="md-dense md-raised md-success">
            {{ $translate("user_store_selection.new_store.open_store") }}
          </md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import { UPDATE_GLOBAL_LOADER } from "@/store"
import { languages, SHARED_STORE } from "../../../Shared/constants/other"
import { ADMIN_LOGIN, LOG_OUT_USER } from "../../../Shared/stores/Shared/constants"
import {
  delete_store_or_user, update_user_info,
  verify_domain_uniqueness
} from "../../constants/endpoints/firebase"
import { ADMIN, ADMIN_STORE, USER_STORE } from "../../constants/others_constants"
import domain_normalizer from "../../methods/domain_normalizer"
import domain_verification from "../../methods/domain_verification"
import email_validator from "../../methods/email_validator"
import { ADD_GLOBAL_ERROR, BUILD_NEW_STORE } from "../../stores/Admin/constants"
import { SET_USER_INFO, SET_USER_PROJECTS } from "../../stores/Admin/user/constants"
import admin_corner_icon from "../utils/admin_corner_icon"
import { stores_preview_domain } from "../../../../data/other_constants"
import replace_spaces_for_dashes from "../../../Shared/methods/replace_spaces_for_dashes"
import get_image_src from "../../../Shared/methods/get_image_src"
import get_correct_domain from "../../methods/get_correct_domain"
import highlighted_button from "./highlighted_button.vue"
import basic_button from "./basic_button.vue"
import triple_arrow_button from "./triple_arrow_button.vue"

export default {
  components: {
    admin_corner_icon,
    highlighted_button,
    basic_button,
    triple_arrow_button
  },
  data() {
    return {
      store_creation_modal: false,
      show_domain_error: false,
      email_modal: false,
      settings_modal: false,
      new_store_message_modal: false,
      account_deletion_acknowledgment: false,
      is_domain_valid: true,
      project_name: "",
      project_domain: "",
      email: "",
      new_store_id: "",
      new_store_name_id: "",
      new_store_domain: "",
      new_store_name: "",
      selected_languages: [],
      languages,
      stores_preview_domain
    }
  },
  computed: {
    ...mapState(USER_STORE, ["projects", "user_info", "is_any_store_admin"]),
    is_email_valid() {
      return this.email && email_validator(this.email)
    },
    correct_stores_domain() {
      return this.get_correct_domain(this.new_store_id, this.new_store_name_id)
    },
    is_new_store() {
      return this.$route?.query?.new_store
    },
  },
  mounted() {
    if (!this.user_info.email) this.email_modal = true
    if (window.zE) window.zE("messenger:set", "zIndex", -1)
  },
  beforeDestroy() {
    if (window.zE) window.zE("messenger:set", "zIndex", 9999)
  },
  methods: {
    ...mapActions(SHARED_STORE, {
      admin_login: ADMIN_LOGIN,
      log_out_user: LOG_OUT_USER
    }),
    ...mapMutations({
      update_loader: UPDATE_GLOBAL_LOADER
    }),
    ...mapMutations(USER_STORE, {
      set_user_projects: SET_USER_PROJECTS,
      set_user_info: SET_USER_INFO
    }),
    ...mapMutations(ADMIN_STORE, {
      add_global_error: ADD_GLOBAL_ERROR,
    }),
    ...mapActions(ADMIN, {
      build_store: BUILD_NEW_STORE
    }),
    replace_spaces_for_dashes,
    get_correct_domain,
    get_store_icon(icon) {
      return typeof icon === "string" ? icon : get_image_src(icon)
    },
    clear_modal() {
      this.project_name = ""
      this.project_domain = ""
      this.show_domain_error = false
    },
    async update_domain(domain) {
      this.is_domain_valid = false
      this.show_domain_error = false

      if (!domain) return this.is_domain_valid = true

      const formatted_domain = domain_normalizer(domain)
      if (formatted_domain && !domain_verification(formatted_domain)) return this.show_domain_error = true

      this.update_loader()
      let is_store_unique = false
      try {
        const { data } = await verify_domain_uniqueness(formatted_domain)
        is_store_unique = data
      } catch ({ message }) {
        this.$emit("add_message", {
          message: this.$translate(`api_messages.${message}`)
        })
      } finally {
        this.update_loader(false)
      }

      if (is_store_unique) {
        this.is_domain_valid = true
        this.project_domain = ""

        this.$nextTick(() => this.project_domain = formatted_domain)
      }
      if (domain === "") this.is_domain_valid = true
    },
    select_store(project_id) {
      // Check that user has known email
      if (!this.email_modal) {
        this.admin_login({ project_id })
        this.$router.push(({
          path: `/admin/${project_id}`,
          ...(this.is_new_store && {
            query: {
              new_store: true
            }
          })
        }))
      }
    },
    async update_user_email() {
      this.update_loader()

      try {
        await update_user_info({
          user_info: {
            ...this.user_info,
            email: this.email
          },
          id: this.user_info.id
        })
        this.set_user_info({
          ...this.user_info,
          email: this.email
        })
        this.email_modal = false
      } catch ({ message }) {
        this.$emit("add_message", { type: "error", message })
      }

      this.update_loader(false)
    },
    async create_store() {
      if (this.show_domain_error) return

      const {
        new_store_id,
        store_name_id,
        store_domain,
        store_name
      } = await this.build_store({
        store_name: this.project_name,
        new_store_domain: this.project_domain,
        selected_languages: this.selected_languages
      })

      this.new_store_id = new_store_id
      this.new_store_name_id = store_name_id
      this.new_store_domain = store_domain
      this.new_store_name = store_name
      this.store_creation_modal = false
      this.clear_modal()
    },
    async delete_account() {
      this.update_loader()
      await delete_store_or_user({ uid: this.user_info.id, deleting_only_user: true })
      this.update_loader(false)
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_admin-constants" as *;
  @use "../../../../styles/_global-constants" as *;

  .user-store-selection {
    padding: 0 $default-size 50px;
    text-align: center;

    &--single {
      background: $pure-white;
      background-image: radial-gradient( circle farthest-corner at 1.3% 2.8%,  rgba(239,249,249,1) 0%, rgba(182,199,226,1) 100.2% );
      min-height: 100vh;
      padding: $default-size $default-size 50px;
    }

    &__warning-message {
      margin-top: $default-size;
      padding: $half-default-size $default-size;
      border-radius: $border-radius;
    }

    &__language {
      img {
        width: 30px;
        max-height: 30px;
        object-fit: contain;
      }

      .md-list-item-text {
        flex-direction: row-reverse;
        justify-content: flex-end;
        column-gap: 10px;
        align-items: center;
      }
    }

    &__stores {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      row-gap: $default-size;
      column-gap: $default-size;
    }

    &__deletion-message {
      margin-top: $half-default-size;
      color: $material-red !important;
    }

    &__title {
      padding: $double-default-size;
      margin-top: 0;
      color: $pure-black !important;
      font-weight: bold;
    }

    &__modal {
      color: $pure-black;
      min-width: 320px !important;

      .md-checkbox-label {
        height: auto !important;
      }
    }

    &__new-store-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border-radius: $border-radius;
      border: 1px solid $pure-black;
      margin: auto 0;
      cursor: pointer;
      transition: $material-transition;

      &:hover {
        background: $pure-black;
        color: $pure-white;

        .md-icon {
          color: $pure-white !important;
        }
      }

      .md-icon {
        font-size: 40px !important;
        color: $pure-black !important;
      }
    }

    &__store {
      padding: $default-size;
      transition: 200ms linear;
      border-radius: $border-radius;
      text-align: left;
      background: $pure-white;
      background-image: radial-gradient( circle farthest-corner at 1.3% 2.8%,  rgba(239,249,249,1) 0%, rgba(182,199,226,1) 100.2% );
      background-size: contain;

      &-single {
        background: transparent;
        background-image: none;
        max-width: 400px;
        margin: $double-default-size auto 0;

        .admin-basic-button {
          font-size: $font-size--medium-large;
        }

        &-title {
          margin: 120px 0 80px !important;
          font-size: 40px;
          font-weight: bold;
          color: $logo-black;

          div {
            color: $logo-blue;
            margin: $default-size 0;
            font-size: 35px;
          }

          @media (max-width: $tablet) {
            margin: 80px 0 $double-default-size !important;
            font-size: 35px;

            div {
              font-size: 30px;
            }
          }
          @media (max-width: $tablet--small) {
            text-align: left;
          }
          @media (max-width: $mobile--large) {
            margin: $double-default-size 0 $double-default-size !important;
          }
          @media (max-width: $mobile--small) {
            margin: 0 0 $double-default-size !important;
          }
        }
      }
    }

    &__error {
      &:after {
        background-color: $material-red !important;
      }
      .md-helper-text {
        color: $material-red !important;
      }
    }

    &__message {
      display: grid;
      grid-template-columns: 1fr;
      margin-top: $double-default-size;
      row-gap: $default-size;

      @media (max-width: $material-medium-breakpoint) {
        margin-top: $default-size;
        column-gap: $half-default-size;
      }

      @media (max-width: $tablet--small) {
        grid-template-columns: 1fr;
      }
    }

    .md-avatar {
      margin-right: $double-default-size;
      border: 1px solid $material-grey--darkest;

      img {
        filter: drop-shadow(0px 0px 2px $pure-black);
        overflow: hidden;
      }
    }

    &__actions {
      position: absolute;
      display: flex;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: space-between;
    }

    img {
      object-fit: cover;
    }

    .md-empty-state {
      padding-top: 80px;

      &-icon {
        width: 260px;
        min-width: 260px;
        height: 260px;
        font-size: 260px!important;
      }
    }
  }
</style>
